'use client'

import type { ReactNode } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import HorizontalScrollComponent from './components'
import { throttle } from '@patrianna/shared-utils'

export type Props = {
  showArrows?: boolean
  children: ReactNode
  className?: string
  classNameContainer?: string
  scrolledItemDataAttr?: string
  scrollPage?: number
  'data-test'?: string
  BackDropNode?: ReactNode
  pathname?: string
  customScroll?: (container: HTMLDivElement) => void
  onScroll?: (container: HTMLDivElement) => void
  scrollToEndEnabled?: boolean
  scrollCallback?: (value: number) => void
}

export default function HorizontalScroll(props: Props) {
  const [isArrowsShown, setShowArrows] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>()
  const scrollRef = useRef<HTMLDivElement>()
  const scrollPageRef = useRef<number>()

  useEffect(() => {
    setShowArrows(props.showArrows)
    if (props.showArrows) {
      checkArrowsVisibility()
    }
  }, [props.showArrows])

  const onMouseOver = useCallback(() => setShowArrows(true), [])
  const onMouseOut = useCallback(() => setShowArrows(false), [])

  useEffect(() => {
    const scroll: HTMLDivElement | undefined = wrapperRef.current

    if (props.showArrows) {
      scroll?.addEventListener('mouseover', onMouseOver)
      scroll?.addEventListener('mouseout', onMouseOut)
    }

    return () => {
      scroll?.removeEventListener('mouseover', onMouseOver)
      scroll?.removeEventListener('mouseout', onMouseOut)
    }
  }, [onMouseOut, onMouseOver, props.showArrows])

  const getScrollDistance = useCallback((): number => {
    if (!scrollRef.current) {
      return 0
    }

    const gridGap = parseInt(getComputedStyle(scrollRef.current).gap, 10) || 12
    const childrenWidth = scrollRef.current.children[0]?.clientWidth || 0

    return childrenWidth + gridGap
  }, [])

  const handleScroll = () => {
    const container = scrollRef.current
    if (!container) {
      return
    }
    throttledCheckArrowsVisibility()
    props.onScroll?.(container)
  }

  const scrollTo = (element: HTMLElement, direction: string, distance: number) => {
    element.scrollBy({ top: 0, left: direction === 'left' ? -distance : distance, behavior: 'smooth' })
  }

  useEffect(() => {
    if (scrollRef.current) {
      props.customScroll?.(scrollRef.current)
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [props.pathname])

  useEffect(() => {
    if (props.scrollPage > scrollPageRef.current) {
      nextScroll()
    } else if (props.scrollPage < scrollPageRef.current) {
      prevScroll()
    }
    scrollPageRef.current = props.scrollPage
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [props.scrollPage])

  // check if 'prev' or 'next' navigation arrows should be hidden.
  // For example, initially we hide 'prev' arrow because content can't be scrolled to left.
  // when user scroll to the end of list we hide 'next' arrow
  const checkArrowsVisibility = () => {
    const container = scrollRef.current
    const wrapper = wrapperRef.current
    if (!container || !wrapper) {
      return
    }

    // check 'prev' arrow
    if (container.scrollLeft === 0) {
      wrapper.classList.add('hidePrevlArrow')
    } else {
      wrapper.classList.remove('hidePrevlArrow')
    }

    // check 'next' arrow
    if (container.scrollWidth <= Math.ceil(container.scrollLeft + container.offsetWidth)) {
      wrapper.classList.add('hideNextlArrow')
    } else {
      wrapper.classList.remove('hideNextlArrow')
    }
  }

  const throttledCheckArrowsVisibility = throttle(checkArrowsVisibility, 500)

  const prevScroll = useCallback(() => {
    const distance = getScrollDistance()

    if (scrollRef.current) {
      scrollTo(scrollRef.current, 'left', distance)
    }
    checkArrowsVisibility()
  }, [getScrollDistance])

  const nextScroll = useCallback(() => {
    const distance = getScrollDistance()

    if (scrollRef.current) {
      scrollTo(scrollRef.current, 'right', distance)
    }
    checkArrowsVisibility()
  }, [getScrollDistance])

  const { className = '', classNameContainer = '', children, BackDropNode } = props

  return (
    <HorizontalScrollComponent
      wrapperRef={wrapperRef}
      scrollRef={scrollRef}
      onScroll={handleScroll}
      prevScroll={prevScroll}
      nextScroll={nextScroll}
      className={className}
      classNameContainer={classNameContainer}
      showArrows={isArrowsShown}
      data-test={props?.['data-test']}
      BackDropNode={BackDropNode}
    >
      {children}
    </HorizontalScrollComponent>
  )
}
