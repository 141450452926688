import type { MutableRefObject, ReactNode } from 'react'
import cx from 'classnames'
import { IconButton } from '@patrianna/core-components'
import { ArrowBottomRight } from 'mdi-material-ui'
import classes from './styles.module.scss'

type Props = {
  showArrows?: boolean
  className: string
  classNameContainer: string
  wrapperRef: MutableRefObject<HTMLDivElement>
  scrollRef: MutableRefObject<HTMLDivElement>
  prevScroll: () => void
  nextScroll: () => void
  onScroll?: () => void
  children: ReactNode
  'data-test'?: string
  BackDropNode?: ReactNode
}

function HorizontalScrollWeb(props: Props) {
  const {
    wrapperRef,
    scrollRef,
    onScroll,
    prevScroll,
    nextScroll,
    className,
    classNameContainer,
    showArrows,
    children,
    BackDropNode,
  } = props

  return (
    <div
      className={cx(classes.scrollWrap, className, 'hidePrevlArrow')}
      ref={wrapperRef}
      data-test={props?.['data-test']}
    >
      {BackDropNode}

      {showArrows && (
        <>
          <IconButton
            className={cx(classes.buttonArrow, classes.prevButtonArrow, 'prev-banner-arrow')}
            onClick={prevScroll}
          >
            <ArrowBottomRight className={classes.buttonArrowIconLeft} />
          </IconButton>
          <IconButton
            className={cx(classes.buttonArrow, classes.nextButtonArrow, 'next-banner-arrow')}
            onClick={nextScroll}
          >
            <ArrowBottomRight className={classes.buttonArrowIconRight} />
          </IconButton>
        </>
      )}

      <div className={cx(classes.scrollContainer, classNameContainer)} onScroll={onScroll} ref={scrollRef}>
        {children}
      </div>
    </div>
  )
}

export default HorizontalScrollWeb
