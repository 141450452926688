'use client'

import { Link } from 'components/Link'
import cx from 'classnames'
import type { GameProductShort, SwimlaneCategoryStyle } from '@patrianna/shared-patrianna-types/store/GameModule'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import images from 'temp/images.json'
import { closeAllDialogs } from 'store/modules/dialog/actions'
import { getMaxPragmaticJackpotsByGameCodeSelector } from 'src/store/modules/pragmaticJackpots/selectors'
import PragmaticJackpot from 'components/PragmaticJackpot'
import { isPragmaticJackpotGameProduct } from 'utils/games'
import { getSlotStartUrlByGameType } from 'utils/navigator/games'
import classes from './styles.module.scss'
import { Picture } from '../Picture'
import { ImageType, type PictureProps } from '../Picture/Picture.types'
import ProductTileTags from 'components/ProductTileTags'

export type Props = {
  game: GameProductShort
  className?: string
  moreRows?: boolean
  handleTileClick?: (gameCode: string) => void
  isSearch?: boolean
  swimlaneStyle?: SwimlaneCategoryStyle
}

function ProductTile({ game, className, handleTileClick, isSearch, swimlaneStyle }: Props) {
  const dispatch = useAppDispatch()
  const maxPragmaticJackpot = useAppSelector((state) => getMaxPragmaticJackpotsByGameCodeSelector(state, game.code))
  const url = `${getSlotStartUrlByGameType(game.type)}/${game.route}`
  const isPragmaticJackpotPlay = isPragmaticJackpotGameProduct(game)

  const pictureProps: PictureProps = {
    src: `${images.bucket}/tiles/${game.code}/source.png`,
    alt: game.title,
    className: classes.image,
    type: isSearch ? ImageType.SEARCH : ImageType.TILE,
    loading: 'lazy',
  }

  return (
    <Link
      href={url}
      className={cx(
        classes.root,
        classes.rootVisible,
        className && {
          [className]: className,
        }
      )}
      onClick={() => {
        handleTileClick?.(game.route)
        dispatch(closeAllDialogs())
      }}
    >
      <div className={classes.imageContainer} data-test={`game-tile-${game.code}`}>
        <ProductTileTags tags={game.tags} tagSize={swimlaneStyle} />
        <Picture {...pictureProps} />
        {isPragmaticJackpotPlay && maxPragmaticJackpot && (
          <PragmaticJackpot className={classes.amountPragmaticJackpot} gameProduct={game} />
        )}
      </div>
    </Link>
  )
}

export default ProductTile
