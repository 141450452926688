import ROUTES from 'temp/routes.json'

export const gameRedirects = (rootURL: string, dynamicIdName: string) => {
  return {
    [`${ROUTES.GAMES_SLOTS}/${rootURL}/[${dynamicIdName}]`]: `${ROUTES.GAMES_SLOTS}/${rootURL}/[${dynamicIdName}]/[currencyName]`,
    [`${ROUTES.GAMES_SLOTS}/${rootURL}/[${dynamicIdName}]/play`]: `${ROUTES.GAMES_SLOTS}/${rootURL}/[${dynamicIdName}]/[currencyName]/play`,
    [`${ROUTES.GAMES_SLOTS}/${rootURL}/[${dynamicIdName}]/play/mobile`]: `${ROUTES.GAMES_SLOTS}/${rootURL}/[${dynamicIdName}]/[currencyName]/play/mobile`,

    [`${ROUTES.GAMES_SLOTS}/${rootURL}/[${dynamicIdName}]/[currencyName]`]: `${ROUTES.GAMES_SLOTS}/${rootURL}/[${dynamicIdName}]`,
    [`${ROUTES.GAMES_SLOTS}/${rootURL}/[${dynamicIdName}]/[currencyName]/play`]: `${ROUTES.GAMES_SLOTS}/${rootURL}/[${dynamicIdName}]/play`,
    [`${ROUTES.GAMES_SLOTS}/${rootURL}/[${dynamicIdName}]/[currencyName]/play/mobile`]: `${ROUTES.GAMES_SLOTS}/${rootURL}/[${dynamicIdName}]/play/mobile`,
  }
}
export const gameRoutesGenerator = (rootUrl: string, dynamicIdName: string) => {
  return {
    [`${ROUTES.GAMES_SLOTS}/${rootUrl}/[${dynamicIdName}]`]: (route: string) =>
      `${ROUTES.GAMES_SLOTS}/${rootUrl}/${route}`,

    [`${ROUTES.GAMES_SLOTS}/${rootUrl}/[${dynamicIdName}]/play`]: (route: string) =>
      `${ROUTES.GAMES_SLOTS}/${rootUrl}/${route}/play`,

    [`${ROUTES.GAMES_SLOTS}/${rootUrl}/[${dynamicIdName}]/play/mobile`]: (route: string) =>
      `${ROUTES.GAMES_SLOTS}/${rootUrl}/${route}/play/mobile`,

    [`${ROUTES.GAMES_SLOTS}/${rootUrl}/[${dynamicIdName}]/[currencyName]`]: (route: string, currencyName: string) =>
      `${ROUTES.GAMES_SLOTS}/${rootUrl}/${route}/${currencyName}`,

    [`${ROUTES.GAMES_SLOTS}/${rootUrl}/[${dynamicIdName}]/[currencyName]/play`]: (
      route: string,
      currencyName: string
    ) => `${ROUTES.GAMES_SLOTS}/${rootUrl}/${route}/${currencyName}/play`,

    [`${ROUTES.GAMES_SLOTS}/${rootUrl}/[${dynamicIdName}]/[currencyName]/play/mobile`]: (
      route: string,
      currencyName: string
    ) => `${ROUTES.GAMES_SLOTS}/${rootUrl}/${route}/${currencyName}/play/mobile`,
  }
}

export function getSlotStartUrlByGameType(gameType: string) {
  if (gameType === 'table') {
    return '/games'
  }

  return ROUTES.GAMES_SLOTS
}
