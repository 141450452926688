import { useRouter } from 'app/context/navigation'
import type { MouseEvent } from 'react'
import type { JSXFunctionSerializer, JSXMapSerializer } from '@prismicio/react'
import { PrismicRichText } from '@prismicio/react'
import { hasLinksInField } from '@patrianna/shared-utils/prismic'
import { getPageHref } from 'utils/getPageHref'
import classes from './styles.module.scss'

type RichTextProps = {
  field: string
  components?: JSXMapSerializer | JSXFunctionSerializer
}

function RichText(props: RichTextProps) {
  const router = useRouter()
  const { field, components } = props
  const isTranslationString = typeof field === 'string'
  const isLinksInField = !isTranslationString && hasLinksInField(field)

  const handleClick = (e: MouseEvent) => {
    const href = getPageHref(e)

    if (href) {
      e.preventDefault()
      router.push(href)
    }
  }

  if (isTranslationString) return <>{field}</>

  return isLinksInField ? (
    <span className={classes.linkWrapper} onClick={handleClick}>
      <PrismicRichText field={field} components={components} />
    </span>
  ) : (
    <PrismicRichText field={field} components={components} />
  )
}

export default RichText
