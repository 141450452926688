import type { TagsType, SwimlaneCategoryStyle } from '@patrianna/shared-patrianna-types/store/GameModule'
import cx from 'classnames'
import classes from './styles.module.scss'
import { SPIN_AND_WINS_TAG, getTagImage, getSecondTagClassName, isDefaultGameProductTags } from './utils'
import { useMemo } from 'react'

type Props = {
  tags: TagsType[]
  tagSize?: SwimlaneCategoryStyle
}

export default function ProductTileTags({ tags, tagSize }: Props) {
  const isDefaultTags = isDefaultGameProductTags(tags)
  const tagImage = getTagImage(tags, tagSize)
  const firstTagStyle = isDefaultTags[0] === SPIN_AND_WINS_TAG ? classes.spinAndWinTag : classes.primaryColorTag
  const secondTagStyle = useMemo(() => getSecondTagClassName(isDefaultTags[1]), [isDefaultTags])

  return (
    <div className={classes.tags}>
      {tagImage ? (
        <img className={tagImage.className} src={tagImage.src} alt={tagImage.alt} />
      ) : (
        <div
          className={cx(
            classes.textTags,
            tagSize === 'large' && classes.textTagsLarge,
            tagSize === 'small' && classes.textTagsSmall,
            tagSize === 'default' && classes.textTagsDefault
          )}
        >
          {isDefaultTags[0] && <span className={firstTagStyle}>{isDefaultTags[0]}</span>}
          {isDefaultTags[1] && <span className={secondTagStyle}>{isDefaultTags[1]}</span>}
        </div>
      )}
    </div>
  )
}
