import type { TagsType, SwimlaneCategoryStyle } from '@patrianna/shared-patrianna-types/store/GameModule'
import tagsConfig from 'temp/productTileTagsConfig.json'
import cx from 'classnames'
import classes from './styles.module.scss'

export const SPIN_AND_WINS_TAG = 'Spins & Wins'
export const NEW_TAG = 'New'

export function getTagImage(tags: TagsType[], tagSize?: SwimlaneCategoryStyle) {
  switch (true) {
    case tags.includes('PJP'):
      return {
        className: cx(classes.logoPragmaticJackpot, tagSize === 'large' && classes.logoPragmaticJackpotLarge),
        src: `${process.env.IMG_ORIGIN}/product-tile-tags/pragmatic-jackpot-tag.png`,
        alt: 'Jackpot Play',
      }
    case tags.includes('monday'):
      return {
        className: cx(classes.logoMondayTag, tagSize === 'large' && classes.logoMondayTagLarge),
        src: `${process.env.IMG_ORIGIN}/product-tile-tags/monday-tag.png`,
        alt: 'Monday kickoff leaderboard',
      }
    case tags.includes('thursday'):
      return {
        className: cx(classes.logoThursdayTag, tagSize === 'large' && classes.logoThursdayTagLarge),
        src: `${process.env.IMG_ORIGIN}/product-tile-tags/thursday-tag.png`,
        alt: 'Thursday tournament',
      }
    case tags.includes('weekend'):
      return {
        className: cx(classes.logoWeekendTag, tagSize === 'large' && classes.logoWeekendTagLarge),
        src: `${process.env.IMG_ORIGIN}/product-tile-tags/weekend-tag.png`,
        alt: 'Weekend slots spotlight',
      }
    default:
      return null
  }
}

export const isDefaultGameProductTags = (tags: TagsType[]) =>
  tagsConfig?.reduce((acc, tagObj: { tag: TagsType; value: string }) => {
    if (tags.includes(tagObj.tag)) {
      acc.push(tagObj.value)
    }

    // show only two tags
    return acc.slice(0, 2)
  }, [])

export const getSecondTagClassName = (tagName: string) => {
  switch (tagName) {
    case SPIN_AND_WINS_TAG:
      return classes.spinAndWinTag
    case NEW_TAG:
      return classes.primaryColorTag
    default:
      return classes.secondaryColorTag
  }
}
