'use client'

import { getLanguageHref } from 'app/utils/getLanguageHref'
import type { LinkProps } from 'next/link'
import NextLink from 'next/link'
import { useParams } from 'next/navigation'
import type { ReactNode, MouseEvent } from 'react'
import NProgress from 'nprogress'

export type Props = {
  href: string
  children?: ReactNode
  className?: string
  target?: '_blank' | ''
  rel?: string
  title?: string
  language?: string
} & LinkProps

export function Link({ children, href, passHref, language, onClick, ...rest }: Props) {
  const { locale } = useParams()
  const externalHref = href?.startsWith('http')
  const languageHref = getLanguageHref({ href, language, locale })

  if (externalHref) {
    return (
      <a href={href} {...rest}>
        {children}
      </a>
    )
  }

  function handleClick(event: MouseEvent<HTMLAnchorElement>) {
    NProgress.configure({ showSpinner: false }).start()

    if (onClick) {
      onClick(event)
    }
  }

  return (
    <NextLink href={languageHref} passHref={passHref} onClick={handleClick} {...rest}>
      {children}
    </NextLink>
  )
}
