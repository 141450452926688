import { createSelector } from '@reduxjs/toolkit'
import type { ReduxState } from 'src/store/types'
import type { PragmaticJackpot } from './types'

const getPragmaticJackpots = (state: ReduxState) => {
  return state.pragmaticJackpot.jackpots
}

export const getPragmaticJackpotsSelector = createSelector(getPragmaticJackpots, (jackpots) => jackpots)

export const getMaxPragmaticJackpotsSelector = createSelector(getPragmaticJackpots, (jackpots) => {
  return jackpots.reduce(
    (acc, jackpot) => (Number(jackpot.amount) > Number(acc?.amount || 0) ? jackpot : acc),
    {} as PragmaticJackpot
  )
})

export const getMaxPragmaticJackpotsByGameCodeSelector = createSelector(
  getMaxPragmaticJackpotsSelector,
  (_: ReduxState, code: string) => code,
  (jackpot, code) => {
    return jackpot.games?.includes(code) ? jackpot.amount : null
  }
)
