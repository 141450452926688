'use client'

import { Link } from 'components/Link'
import cx from 'classnames'
import { IconButton } from '@patrianna/core-components'
import { useTranslation } from 'context/translationContext'
import { ArrowIcon } from 'components/GameRowHeader/ArrowIcon'
import Img from 'components/Img'
import classes from './styles.module.scss'
import { useAuth } from 'app/context/auth'

type Props = {
  title: string
  url?: string
  count?: number
  iconImg?: string
  className?: string
  scrollPrev?: () => void
  scrollNext?: () => void
  showArrows?: boolean
  freeCoinsTag?: boolean
  endScroll?: () => void
  isPrevDisabled?: boolean
  isNextDisabled?: boolean
  titleWithTag?: boolean
  largeTitle?: boolean
}

export default function GameRowHeader(props: Props) {
  const { isLoggedIn } = useAuth()
  const t = useTranslation()

  return (
    <div className={cx(classes.root, props.className)}>
      <h3
        className={cx(classes.titleWithLink, {
          [classes.titleWithTag]: props.titleWithTag,
          [classes.largeTitle]: props.largeTitle,
        })}
        data-test={`title-${props.title}`}
      >
        <span>{props.title}</span>
        {props.freeCoinsTag && isLoggedIn && (
          <span className={classes.tag} onClick={props.endScroll}>
            {t('common.daily_reward')}
            <Img
              src={`${process.env.IMG_ORIGIN}/icons/arrow-right-outlined.png`}
              className={classes.arrowIcon}
              alt='Arrow right icon'
            />
          </span>
        )}
      </h3>

      {props.showArrows && (
        <>
          <IconButton className={classes.buttonArrow} onClick={props.scrollPrev} disabled={props.isPrevDisabled}>
            <ArrowIcon className={classes.buttonArrowIconLeft} alt='scroll-left' />
          </IconButton>

          <IconButton className={classes.buttonArrow} onClick={props.scrollNext} disabled={props.isNextDisabled}>
            <ArrowIcon className={classes.buttonArrowIconRight} alt='scroll-right' />
          </IconButton>
        </>
      )}

      {props.url && (
        <Link href={props.url} className={classes.link} data-test={`show-all-${props.title}`}>
          {t('common.see_all')}
          <ArrowIcon className={classes.icon} alt='view all' />
        </Link>
      )}
    </div>
  )
}
